/**
 * Renders alternative categories list
 *
 * @flow
 */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../layouts/DefaultLayout';
import Category from '../components/AlternativeCategory';
import { PageHeader, IntroText } from '../styles';

const Row = styled.div``;
const Col = styled.div`
  padding-top: calc(var(--grid-gutter-width) / 2);
  padding-bottom: calc(var(--grid-gutter-width) / 2);
`;

type Props = {
  data: {
    categories: {
      edges: Array<{
        node: {
          name: string,
          path: string,
          count: number,
          acf?: {
            featuredImage?: {
              alt: string,
              localFile?: {
                childImageSharp: {
                  fluid: any,
                },
              },
            },
          },
        },
      }>
    },
  },
};

const AlternativeCategoriesPage = ({ data }: Props) => (
  <Layout>
    <PageHeader>
      <h1>Ethical Alternative Categories</h1>
      <IntroText>We are defined by what we do, create, buy or repair.</IntroText>
    </PageHeader>
    <Row className="row">
      {data.categories.edges.map(({ node }) => (
        <Col className="col-sm-6 col-md-4 col-lg-3 col-xl-2" key={node.path}>
          <Category category={node} />
        </Col>
      ))}
    </Row>
  </Layout>
);

export default () => (
  <StaticQuery
    query={graphql`
      query {
        categories: allWordpressWpAlternativeCategories(sort: {fields: count, order: DESC}, filter: {count: {gt: 0}}) {
          edges {
            node {
              name
              path
              count
              acf {
                featuredImage: featured_image {
                  alt: alt_text
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <AlternativeCategoriesPage data={data} />}
  />
);
